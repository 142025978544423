import { createApp } from 'vue'
import { createIntercom } from '@mathieustan/vue-intercom'
import { useCore } from '@tenant/core'
import Vapor from 'laravel-vapor'
import App from './app.vue'
import * as Sentry from '@sentry/vue'
import posthog from '@tenant/core/posthog'

import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'

Vapor.withBaseAssetUrl(
    import.meta.env.VITE_VAPOR_ASSET_URL || import.meta.env.VITE_API_URL
)

const init = () => {
    const app = createApp(App)

    // Intercom
    const appId =
        import.meta.env.MODE === 'production' ? 'p6fvsaum' : 'jfnd7780'
    const intercom = createIntercom({ appId })
    app.use(intercom)

    if (import.meta.env.MODE === 'production') {
        app.use(posthog)
    }
    const { router } = useCore(app)

    if (import.meta.env.MODE === 'production') {
        Sentry.init({
            app: app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                Sentry.replayIntegration({
                    unmask: ['.sentry-unmask'],
                }),
                Sentry.browserTracingIntegration({ router }),
            ],
            environment: import.meta.env.MODE,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            ignoreErrors: ['ValidationError', /Network Error/i],
        })

        initializeFaro({
            url: 'https://faro-collector-prod-eu-west-0.grafana.net/collect/54f415a9e3ea87f9a47822a4d339a61a',
            app: {
                name: import.meta.env.VITE_APP_NAME,
                version: '1.0.0',
                environment: import.meta.env.MODE,
            },
            sessionTracking: {
                enabled: true,
                samplingRate: 0.5,
            },
            instrumentations: [
                ...getWebInstrumentations(),
                new TracingInstrumentation(),
            ],
        })
    }

    router.isReady().then(() => {
        app.mount('#app')
    })
}

init()
